.container {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  background: #fff;
}

.pulse-button {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 100px;
    height: 100px;
    font-size: 1.3em;
    font-weight: light;
    font-family: 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    letter-spacing: -1px;
    color: white;
    border: none;
    border-radius: 50%;
    background: #5a99d4;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(90, 153, 212, .5);
    animation: pulse 1.5s infinite;
  }
  /* .pulse-button:hover {
    animation: none;
  } */
  
  @keyframes pulse {
    0% {
      @include transform(scale(.9));
    }
    70% {
      @include transform(scale(1));
      box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }
      100% {
      @include transform(scale(.9));
      box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
  }