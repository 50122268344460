.Main_Container{
    background: linear-gradient(to bottom, rgba(0, 147, 184, 1), rgba(0, 122, 152, 0.99) 25%, rgba(0, 33, 42, 0.96) 95%, rgba(0, 19, 23, 0.92));
    height: 100%;
    color: #fff;
    padding: 15px 20px;
    overflow-x: hidden;
    flex-wrap: nowrap !important;
}

.Main_Container .Top_Container{
    padding-top: 100px;
    padding-bottom: 100px;
    transition: all .5s;
}

.Main_Container .Top_Container_OnLogin{
    padding-top: 40px;
    padding-bottom: 50px;
}

.Main_Container .Top_Container_OnRegister{
    padding-top: 0px;
    padding-bottom: 20px;
}


.Main_Title{
    font-size: 5rem !important;
}
.Main_Container .Top_Container_OnRegister .Main_Title{
    font-size: 3.8rem !important;
}
.Main_Container .Top_Container_OnLogin .Main_Title{
    font-size: 4rem !important;
}
.Main_Title .Small_Title{
    font-size: 0.6em;
}



.Btns_Container{
    max-width: 270px;
    margin: 10px auto 0 auto !important;
}

.Btns_Container button{
    width: 100%;
    border-radius: 30px;
}
.Btns_Container a{
    text-decoration: none;
}

